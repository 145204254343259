<ngx-spinner [ngStyle]="this.sharedService.customStyle('spinnerTheme')" type="square-jelly-box"><h3 [ngStyle]="this.sharedService.customStyle('spinnerTheme')">Loading...</h3></ngx-spinner>
<div [ngStyle]="this.sharedService.customStyle('bodyTheme')" style="height: auto;"  *ngIf="{offerStatus: offerStatus$ | async, error: offerErrors$ | async} as data" >                        
    <div [hidden]="data.error ? true : false" > 
        <div *ngIf="data.offerStatus" style="width: 100vw; height: 100vh;">
            <div style="
                    
                        /* vertical center */
                        margin: 0;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        /* vertical center */

                        width: auto;
                        min-width: 380px !important;
                        max-width: 490px !important;
                        height: auto;
                        max-height: 221 !important;
                        font-size: large;
                        text-align: center;" >

                <mat-card 
                    class="mat-elevation-z0"
                    [ngStyle]="this.sharedService.customStyle('cardTheme')"
                    style="height: 40px; margin-left: auto; margin-right: auto;">
                    <h3 [ngStyle]="this.sharedService.customStyle('cardTheme')"
                    style="margin-top: auto; margin-bottom: auto; font-size: larger;"
                    > Upgrade Status </h3>                
                </mat-card>

                <div style="height: 5px;"></div>
                
                <mat-card *ngIf="this.offerStatus===this.OfferStatus.REJECTED" class="mat-elevation-z0" [ngStyle]="this.sharedService.customStyle('cardTheme')">
                    <p [ngStyle]="this.sharedService.customStyle('cardTheme')" 
                    style="margin-top: auto; margin-bottom: auto; height: auto; max-height: 3em !important; text-align: center; 
                     
                    word-wrap: break-word; overflow-wrap: break-word;"> 
                    {{this.getStatusMessage(this.offerStatus) }} </p>                
                </mat-card>
                
                <div *ngIf="this.offerStatus===this.OfferStatus.REJECTED" style="height: 5px;"></div>
                
                <table 
                    mat-table 
                    [dataSource]="this.offerStatusData" 
                    class="mat-elevation-z0"                 
                    style="margin-left: auto; margin-right: auto;"
                    [ngStyle]="this.sharedService.customStyle('cardTheme')">

                    <ng-container matColumnDef="name">
                      <th mat-header-cell *matHeaderCellDef [ngStyle]="this.sharedService.customStyle('cardTheme')"> Item </th>
                      <td mat-cell *matCellDef="let element" [ngStyle]="this.sharedService.customStyle('cardTheme')"> 
                        {{element.name}} 
                      </td>
                    </ng-container>
                  
                    <ng-container matColumnDef="value">                        
                      <th mat-header-cell *matHeaderCellDef [ngStyle]="this.sharedService.customStyle('cardTheme')"> Description </th>
                      <td mat-cell *matCellDef="let element" [ngStyle]="this.sharedService.customStyle('cardTheme')">                                       
                        <div *ngIf="element.name==='Property:'">{{element.value | titlecase}}  </div>
                        
                        <div *ngIf="element.value===this.OfferStatus.ACCEPTED" style="color: #77b300; font-weight: 500;">{{element.value | titlecase}}</div>
                        <div *ngIf="element.value===this.OfferStatus.PENDING" style="color: #ff8800;">{{element.value | titlecase}}  </div>
                        <div *ngIf="element.value===this.OfferStatus.REJECTED" style="color: #cc0000;">Declined</div>

                        <div *ngIf="element.value!==this.OfferStatus.ACCEPTED && element.value!==this.OfferStatus.PENDING && 
                                    element.value!==this.OfferStatus.REJECTED && element.name!=='Conf Number:' && element.name!=='Property:'">{{element.value | titlecase}}</div>
                        <div *ngIf="element.name==='Conf Number:'">{{element.value}}</div>                        
                      </td>
                    </ng-container>
                  
                    <!-- <ng-container matColumnDef="notes">
                      <th mat-header-cell *matHeaderCellDef [ngStyle]="this.sharedService.customStyle('cardTheme')"> Notes </th>
                      <td mat-cell *matCellDef="let element" [ngStyle]="this.sharedService.customStyle('cardTheme')"> {{element.notes}} </td>
                    </ng-container> -->                  
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  </table>
            </div>
        </div>
    </div>
    <!-- <app-offer-not-available [hidden]="data.error ? false : true"></app-offer-not-available>                -->
</div> 
