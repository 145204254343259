import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SharedService } from '../../_services/shared.service';
import { OfferService } from '../../_services/offer/offer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { OfferNotAvailableComponent } from '../offer-not-available/offer-not-available.component';
import { CodeInputComponent, CodeInputModule } from 'angular-code-input';
import { AuthService } from '../../_services/auth.service';
import { Observable, catchError, fromEvent, map, mergeMap, shareReplay } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import Utils from '../../_utils/utils';
import { ResponsiveMode } from '../../enum/responsiveModes';
import { Offer } from '../../model/offer';
import { OfferRefreshOfferComponent } from '../offer-refresh-offer/offer-refresh-offer.component';

@Component({
  selector: 'app-offer-token-validation',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    OfferNotAvailableComponent,
    NgxSpinnerModule,
    CodeInputModule,
    OfferRefreshOfferComponent
  ],
  templateUrl: './offer-token-validation.component.html',
  styleUrl: './offer-token-validation.component.css'
})
export class OfferTokenValidationComponent implements OnInit, AfterViewInit, OnDestroy {


  private propertyId: string = '';
  private offerAliasId: string = '';
  viewMode: string = ResponsiveMode.horizontal;
  
  @ViewChild('cardButton') button: any;
  @ViewChild('codeInput') codeInput!: CodeInputComponent;
  tokenButton$!: Observable<any>;

  invalidCode: boolean = false;
  offerError: boolean = false;
  offerRefresh: boolean = false;

  constructor(public sharedService: SharedService,
              private responsive: BreakpointObserver,
              private authService: AuthService,
              private offerService: OfferService,              
              private router: Router, 
              private route: ActivatedRoute) { 
  }


  ngOnInit(): void {
    if(localStorage.getItem('offerError')){
      if(localStorage.getItem('offerError') === 'true' && localStorage.getItem('error_offer_alias_id') === this.offerAliasId){
        this.offerError = true;
      } else {
        this.offerError = false;
        localStorage.setItem('offerError', 'false');
        localStorage.setItem('error_offer_alias_id', '');
      }
    }
    this.propertyId = this.route.snapshot.paramMap.get('prop_id') ? this.route.snapshot.paramMap.get('prop_id') as string : '';
    this.offerAliasId = this.route.snapshot.paramMap.get('alias_id') ? this.route.snapshot.paramMap.get('alias_id') as string : '';    
  }

  ngAfterViewInit(): void {
    this.tokenButton$ = fromEvent(this.button.nativeElement, 'click').pipe(
      shareReplay(1),
      map(() => this.authService.requestUpgradeToken(this.offerAliasId, this.propertyId).subscribe())
    );

    this.tokenButton$.subscribe(() => {
      this.codeInput.reset();
      this.invalidCode = false;
    })
    
  }

  ngOnDestroy(): void {
    this.offerError = false;
    localStorage.setItem('offerError', 'false');
    localStorage.setItem('error_offer_alias_id', '');
  }

  resendButtonEnabled: boolean = true; 

  // this called every time when user changed the code
  onCodeChanged(code: string) {
  }
  
  // this called only if user entered full code
  onCodeCompleted(code: string) {

    this.resendButtonEnabled = false;

    let offer = JSON.parse(localStorage.getItem('offer') as string) as Offer; // persisted offer
    const validateRequest$ = this.authService.validateRequest(this.propertyId, this.offerAliasId, code);
    const acceptOffer$ = this.offerService.setOfferAccepted(offer).pipe(
      catchError((error) => {

        // console.log(error, 'email offer error'); 
        // this.offerRefresh = true;
        this.resendButtonEnabled = true;
        this.offerError = true;        
        localStorage.setItem('offerError', 'true');
        localStorage.setItem('error_offer_alias_id', this.offerAliasId);
        return error}),
      map(res => {

        this.emailOfferUpgradeAcknowledgement().subscribe();
        this.navigateToOfferConfirmation()
        
        this.resendButtonEnabled = true;
        return res;
      }),
      shareReplay(1)
    );

    validateRequest$.pipe(
      mergeMap(res => 
        { 
          if(res['data'] === true) { 
            return acceptOffer$; 
          } else { 
            this.invalidCode = true;
            this.resendButtonEnabled = true;
          }
          return res;
        }
      ),
      shareReplay(1)
    ).subscribe();
  }

  navigateToOfferConfirmation(){
    this.router.navigate(['/offerConfirmation/' + this.offerAliasId + '/' + this.propertyId], { relativeTo: this.route });
  }

  emailOfferUpgradeAcknowledgement(): Observable<any> {
    return this.offerService.emailOfferUpgradeAcknowledgement(this.propertyId, this.offerAliasId, this.sharedService.offer.offer_set_id, this.sharedService.offer.offer_id); 
  }

  responsiveStyle(styleObj: {[key: string]: string}): {[key: string]: boolean} {   
    return Utils.responsiveStyle(this.viewMode, styleObj);
  }
    

}
