<!-- This header doesn't really belong here but it is difficult to extract and keep consistent styling' -->
<div *ngIf="offer" [hidden]="viewMode!=responsiveMode.horizontal" class="card-header-container-desktop" [ngStyle]="this.sharedService.customStyle('cardHeaderTheme')">             
    <mat-card-header class="card-header-desktop container" >
            <mat-card-title class="card-title-desktop">{{offer.amenities.upsell_room_name}} </mat-card-title>                                    
    </mat-card-header>
    <mat-card-header class="card-header-desktop card-header-price-desktop">
            <p class="card-title-desktop card-title-price-desktop " style="font-weight: bold; margin-top: -3px;">
                <!-- <span *ngIf="this.viewMoreToggle" style="font-weight: bold;">{{this.offerIndex(index, this.viewMoreToggle, this.offers!.length)}}</span> <span *ngIf="this.viewMoreToggle"> | </span> -->
                <span style="text-decoration: line-through; color: rgba(255, 255, 255, 0.60); font-size: 14px !important; font-weight: normal;" >{{offer.bar_price | currency:'USD' : 'symbol' : '1.0-0' }}</span> {{offer.ofr_price | currency:'USD' : 'symbol' : '1.0-0'}}/nt
            </p>
    </mat-card-header>
</div>

<div style="margin-bottom: 14px;" *ngIf="offer" [ngStyle]="this.sharedService.customStyle('iconGroupTheme')" class="icon-content"  [ngClass]="this.responsiveStyle({ 'vertical': 'icon-content-handset', 'horizontal': 'icon-content-desktop'})">                                                      
    <div *ngFor="let icon of offer.amenities.icons;">
        <div [ngClass]="this.responsiveStyle({ 'vertical': 'icon-container-handset', 'horizontal': 'icon-container-desktop'})"
             *ngIf="icon.flg_active">
            <img class="icon-hotel" [ngClass]="this.responsiveStyle({ 'vertical': 'icon-hotel-handset', 'horizontal': 'icon-hotel-desktop',})"                         
                src='{{ "assets/svg/" + icon.svg_name + this.sharedService.iconColor + ".svg" }}' alt='{{icon.alt_alias}}'/>                         
            <p class="icon-label" [ngClass]="this.responsiveStyle({ 'vertical': 'icon-label-handset', 'horizontal': ''})">
            {{icon.display_name | titlecase}}</p>                         
        </div>
    </div>
    <p *ngIf="this.viewMoreToggle && this.viewMode === this.responsiveMode.horizontal" [ngStyle]="this.sharedService.customStyle('txtTheme')" style="font-weight: bold; margin-left: auto; margin-right: 0px; float: right; text-align: right; margin-right: 25px;">{{this.offerIndex(index, this.viewMoreToggle, this.offers!.length)}}</p>
</div>
