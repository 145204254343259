import { Component } from '@angular/core';

@Component({
  selector: 'app-unsubscribe-from-emails',
  standalone: true,
  imports: [],
  templateUrl: './unsubscribe-from-emails.component.html',
  styleUrl: './unsubscribe-from-emails.component.css'
})
export class UnsubscribeFromEmailsComponent {

}
