
<div style="width: 95%; max-width:780px; margin-left: auto; margin-right: auto; margin-top: 25px;">
    <p style="font-weight: bold;">Privacy Policy for LuxeHotelUpgrades.com</p>
    <p>Effective Date: November 1, 2024</p>
    <p style="font-weight: bold; ">Data Controller</p>
        <p style="line-height: 0.5; margin-top: 25px;">Luxe Pricing</p>
        <p style="line-height: 0.5;">2831 St. Rose Pkwy. Suite 200 Henderson, NV 89052, USA</p>
        <p style="line-height: 0.5;">privacy&#64;luxehotelupgrades.com</p>
        <p style="line-height: 0.5;">+1 (888) 711-8148</p>
    
    <p style="font-weight: bold; margin-top: 25px;">1. Information We Collect</p>
    <p class="wrap-text">
        We collect personal information from hotel guests to deliver room upgrade offers on behalf of hotels. This information may include:
    </p>
    <ul style="line-height: 2.0;"> 
        <li><span style="font-weight: bold;">Identifiers:</span> Name, email address, reservation confirmation number.</li>
        <li><span style="font-weight: bold;">Commercial Information:</span> Reservation details, including arrival date and room type.</li>
        <li><span style="font-weight: bold;">Internet or Other Electronic Network Activity</span> (if applicable): IP address, browsing history on our site.</li>
    </ul>
    <p style="font-weight: bold;">2. Legal Basis for Processing</p>
    <p class="wrap-text">
        Our processing of your personal information is based on:
    </p>
    <ul style="line-height: 2.0;"> 
        <li><span style="font-weight: bold;">Contractual Necessity:</span> To fulfill our service of providing room upgrade offers as part of your booking experience.</li>
        <li><span style="font-weight: bold;">Legitimate Interests:</span> To enhance your stay by offering personalized upgrades, aligning with your expectations.</li>
        <li><span style="font-weight: bold;">Internet or Other Electronic Network Activity</span> (if applicable): IP address, browsing history on our site.</li>
    </ul>
    <p style="font-weight: bold;">3. How We Use and Protect Your Information</p>
    <p class="wrap-text">
        We use your information to create and deliver personalized room upgrade offers. We implement appropriate technical and organizational measures, such as encryption and restricted access controls, to protect your data against unauthorized access.
    </p>
    <p style="font-weight: bold;">4. Sharing Your Information</p>
    <p class="wrap-text">
        We share your information only with the hotel you have booked to facilitate the room upgrade offer. We do not sell or share your information with third parties for unrelated purposes.
    </p>
    <p style="font-weight: bold;">5. Data Retention</p>
    <p class="wrap-text">
        We retain your personal data for up to 45 days after your stay, after which it is deleted or anonymized unless otherwise required by law.
    </p>
    <p style="font-weight: bold;">6. Provision of Personal Data</p>
    <p class="wrap-text">
        Providing your personal data is necessary to receive room upgrade offers. Without this information, we cannot provide these personalized services.
    </p>
    <p style="font-weight: bold;">7. Your Privacy Rights</p>
    <p class="wrap-text">
        Depending on your jurisdiction (e.g., GDPR in the EU or California privacy laws), you may have the following rights:
    </p>
    <ul style="line-height: 2.0;"> 
        <li><span style="font-weight: bold;">Right to Access:</span> Request access to the personal information we have collected about you.</li>
        <li><span style="font-weight: bold;">Right to Rectification:</span> Correct any inaccurate or incomplete personal information.</li>
        <li><span style="font-weight: bold;">Right to Deletion:</span> Request deletion of your personal information, subject to certain exceptions.</li>
        <li><span style="font-weight: bold;">Right to Restrict Processing:</span> Restrict how we process your data in certain circumstances.</li>
        <li><span style="font-weight: bold;">Right to Data Portability:</span> Receive a copy of your personal data in a commonly used format.</li>
        <li><span style="font-weight: bold;">Right to Object:</span> Object to the processing of your personal data under certain conditions.</li>
        <li><span style="font-weight: bold;">Right to Withdraw Consent:</span> If our processing is based on consent, you have the right to withdraw your consent at any time by contacting us.</li>
        <li><span style="font-weight: bold;">Right to Non-Discrimination:</span> We will not discriminate against you for exercising any of your privacy rights.</li>
    </ul>            
    <p style="font-weight: bold;">8. Right to Lodge a Complaint</p>
    <p class="wrap-text">
        If you believe that our processing of your personal data infringes data protection laws, you have the right to lodge a complaint with a supervisory authority in your EU member state.
    </p>
    <p style="font-weight: bold;">9. How to Exercise Your Rights</p>
    <p class="wrap-text">
        You can submit requests by:
    </p>
    <ul style="line-height: 2.0;"> 
        <li>Calling us toll-free at +1 (888) 711-8148</li>
        <li>Emailing us at privacy&#64;luxehotelupgrades.com</li>
    </ul>    
    <p style="font-weight: bold;">10. International Data Transfers</p>
    <p class="wrap-text">
        We transfer your data to servers located in the United States. We use Standard Contractual Clauses approved by the European Commission to ensure your data is protected.
    </p>
    <p style="font-weight: bold;">11. Automated Decision-Making</p>
    <p class="wrap-text">
        We use automated processes to personalize room upgrade offers based on your reservation details. These processes do not produce legal or similarly significant effects on individuals.
    </p>
    <p style="font-weight: bold;">12. Use of Cookies and Tracking Technologies</p>
    <p class="wrap-text">
        We use cookies and similar tracking technologies on our website to enhance user experience. You can manage your cookie preferences through your browser settings.
    </p>
    <p style="font-weight: bold;">13. Financial Incentives</p>
    <p class="wrap-text">
        We do not offer financial incentives in exchange for your personal information.
    </p> 
    <p style="font-weight: bold;">14. Children’s Privacy</p>
    <p class="wrap-text">
        Our services are not directed at children under the age of 16, and we do not knowingly collect personal information from children.
    </p>
    <p style="font-weight: bold;">15. Updates to This Policy</p>
    <p class="wrap-text">
        We may update this Privacy Policy periodically to reflect changes in our practices or legal requirements. We will post any changes on this page and update the effective date above.
    </p>
    <p style="font-weight: bold;">16. Contact Us</p>
    <p class="wrap-text">
        For questions or concerns about this Privacy Policy or your privacy rights, please contact us at privacy&#64;luxehotelupgrades.com.
    </p>
</div>
