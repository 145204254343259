import { Component } from '@angular/core';

@Component({
  selector: 'app-site-terms-and-conditions',
  standalone: true,
  imports: [],
  templateUrl: './site-terms-and-conditions.component.html',
  styleUrl: './site-terms-and-conditions.component.css'
})
export class SiteTermsAndConditionsComponent {

}
