import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {Observable, throwError as observableThrowError} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {JwtHelperService} from '@auth0/angular-jwt';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { HttpService } from './http.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private domain: string = '';
  private _loginUrl: string = '';
  private _url: string = '';
  private _jwtHelper = new JwtHelperService();

  

  constructor(@Inject(DOCUMENT) private _doc: Document, 
              private http: HttpClient,
              private httpService: HttpService,
              @Inject(PLATFORM_ID) private platformId: Object) {
    
    this.domain = environment.domain;
    this._loginUrl = this.domain + 'auth/publicToken';
    this._url = this.domain + 'auth/';
  }

  // public login(): Observable<any> {

  //   const headers = new HttpHeaders();
  //   headers.append('Content-Type', 'application/json');

  //   return this.http
  //     .post(
  //       this._loginUrl,
  //       {headers: headers}
  //     ).pipe(
  //       map(
  //         response => {
  //           const d:any = response;
  //           if (d['jwt']) {
  //             this.storeToken(d);
  //           }
  //           return d;
  //         }),
  //         shareReplay(1)
  //         );

  // }

  public requestUpgradeToken(offer_alias_id: string, prop_id: string): Observable<any>{
    const url = this._url  + 'requestUpgradeToken';
    return this.httpService
      .post(
        url,
        {'alias_id' : offer_alias_id,
         'prop_id' : prop_id}
      ).pipe(shareReplay(1));
  }

  public validateRequest(prop_id: string, offer_alias_id: string, token: string): Observable<any>{
    const url = this._url  + 'validateRequest';
    const body = 
      JSON.stringify({
        'alias_id': offer_alias_id,
        'prop_id': prop_id,
        'token': token
      });

    return this.httpService.post(url, body);
  }

  public isLoggedIn() {
    return (localStorage.getItem('id_token'));
  }

  private storeToken(token: any) {
      
      localStorage.setItem('id_token', token.jwt);
      localStorage.setItem('XSRF-TOKEN', token['xsrf-token']); 
  }

  public getTokenInfo() {
    return JSON.parse(localStorage.getItem('user')!);
  }

  public getPerms() {
    return this.getTokenInfo() ? this.getTokenInfo()['perm'] : [];
  }

}
