<!-- <ngx-spinner [ngStyle]="this.sharedService.customStyle('spinnerTheme')" type="ball-grid-pulse"><h3 [ngStyle]="this.sharedService.customStyle('spinnerTheme')">Loading...</h3></ngx-spinner> -->
<!-- *ngIf="{offerConfirmation: offerConfirmation$ | async, error: offerErrors$ | async} as data" -->
<div [ngStyle]="this.sharedService.customStyle('bodyTheme')" style="height: auto;">                        
    <!-- [hidden]="data.error ? true : false" -->
    <div *ngIf="!this.offerError">
        <!-- *ngIf="data.offerConfirmation" -->
        <div style="width: 100vw; height: 100vh;">
            <mat-card [ngStyle]="this.sharedService.customStyle('cardTheme')"
                style="                    
                    /* vertical center */
                    margin: 0;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    /* vertical center */

                    padding-left: 50px;
                    padding-right: 50px;
                    padding-bottom: 50px;
                    padding-top: 50px;

                    width: 25%;
                    min-width: 380px !important;
                    height: auto;
                    max-height: 221 !important;
                    font-size: large;
                    text-align: center;"

                    class="not-available-text"   
                >
                <p [ngStyle]="this.sharedService.customStyle('cardTheme')">Please enter the 6 digit code sent to your email.</p>        

                <code-input #codeInput
                            [isCodeHidden]="false"
                            [codeLength]="6"
                            (codeChanged)="onCodeChanged($event)"
                            (codeCompleted)="onCodeCompleted($event)"
                            [ngStyle]="this.sharedService.customStyleMulti(['codeInputBorderTheme', 'codeInputBottomBorderTheme', 'codeInputFontColorTheme'])"                        
                            >
                </code-input>
                <br>
                <p *ngIf="this.invalidCode" style="color: red;">*The code you entered was invalid.</p>        

                <button class="button-request" #cardButton 
                [hidden]="!this.resendButtonEnabled"
                [ngStyle]="this.sharedService.customStyle('buttonRequestTheme')"
                [ngClass]="this.responsiveStyle({ 'vertical': 'card-action-handset', 'horizontal': 'card-action-desktop'})"
                style="                    
                        /* vertical center */
                        margin-left: auto;
                        margin-right: 0px;
                        float: right;
                        width: 160px !important;
                        height: 40px;
                      "
                > RESEND CODE
                </button>
            </mat-card>
        </div>
    </div>

    <app-offer-refresh-offer *ngIf="this.offerError"></app-offer-refresh-offer>

    <!-- <app-offer-not-available *ngIf="this.offerError"></app-offer-not-available> -->
</div> 
