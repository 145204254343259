

<div style="display: inline-block; width: 100vw; height: 100vh;">
    <div *ngIf="!this.offerError"
        style=
            "
                /* vertical center */
                margin: 0;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                /* vertical center */

                padding-left: 50px;
                padding-right: 50px;
                padding-bottom: 50px;
                padding-top: 50px;

                width: 25%;
                min-width: 380px !important;
                height: auto;
                max-height: 221 !important;
                font-size: large;
                text-align: center;

                display: grid;
        ">
        <h3>Email Verification</h3>
            <!-- [ngClass]="{ 'is-invalid': submitted && f.email.errors }"  -->    
        <!-- style="background-color: black;" -->
        <div class="form-group" >
            <label style="text-align: left; margin-left: 0px; margin-right: auto; float: left;" >Email</label>
            <input #_email [ngStyle]="this.sharedService.customStyle('formTheme')" type="text" [formControl]="email" class="form-control" (blur)="updateErrorMessage()" required/>
            @if (email.invalid) {
                <mat-error style="text-align: left; margin-left: 0px; margin-right: auto; float: left; color: red;" >{{errorMessage}}</mat-error>
            }
        </div>
        <div style="height: 15px;"></div>
        <div class="form-group">
            <label style="text-align: left; margin-left: 0px; margin-right: auto; float: left;" >Confirm Email</label>
            <input #_emailCheck [ngStyle]="this.sharedService.customStyle('formTheme')" type="text" [formControl]="emailCheck" class="form-control" (blur)="updateErrorMailCheckMessage()" required/>
            @if (emailCheck.invalid) {
                <mat-error style="text-align: left; margin-left: 0px; margin-right: auto; float: left; color: red;" >{{errorEmailCheckMessage}}</mat-error>
            }
        </div>       
        <div style="height: 15px;"></div>
        <button class="button-request" #cardButton 
            (click)="this.updateGuestEmail()"
            class="button-request button-request-terms" 
            [disabled]="!this.email.valid || !this.emailCheck.valid || !this.sendEmailButtonEnabled"
            [ngStyle]="{'background-color': !this.email.valid || !this.emailCheck.valid ? 'rgba(221,29,84,0.75)' : 'rgba(221,29,84,1)' }"
            [ngClass]="this.responsiveStyle({ 'vertical': 'card-action-handset', 'horizontal': 'card-action-desktop'})"
            style="                    
                    /* vertical center */
                    margin-left: auto;
                    margin-right: 0px;
                    float: right;
                    width: 120px !important;
                    text-shadow: 0 0 0px #ffffff;                      
                    height: 40px; 
                    color: whitesmoke !important; 
                "
            > CONFIRM
        </button>
    </div>
    <app-offer-not-available *ngIf="this.offerError"></app-offer-not-available>
</div>

<div style="height: 25px;" ></div>



<!-- <app-offer-token-validation></app-offer-token-validation> -->