
<div [ngStyle] = "this.sharedService.customStyle('bodyTheme')" style="height: auto;" >
    <router-outlet ></router-outlet>
        <div style="display: grid; align-items: end; 
            margin-left: auto; margin-right: auto;
            height: 50px; width: 240px; ">

            <div style="width: 240px; margin-left: auto; margin-right: auto; text-align: center;" [ngClass]="this.responsiveStyle({ 'vertical': 'other-options-handset', 'horizontal': 'other-options-desktop'})">
                <a [ngStyle]="this.sharedService.customStyle('txtAnchorTheme')"  class="anchorize" (click)="navigatePrivacy()" style="cursor: pointer; margin-left: auto; margin-right: auto; font-size: small;">Privacy Policy</a>&nbsp;
                <a [ngStyle]="this.sharedService.customStyle('txtAnchorTheme')"  class="anchorize" (click)="navigateTerms()" style="cursor: pointer; margin-left: auto; margin-right: auto; font-size: small;">Terms of Service</a>
            </div>   
        </div>
</div>
