import { Component } from '@angular/core';

@Component({
  selector: 'app-site-privacy-policy',
  standalone: true,
  imports: [],
  templateUrl: './site-privacy-policy.component.html',
  styleUrl: './site-privacy-policy.component.css'
})
export class SitePrivacyPolicyComponent {

}
